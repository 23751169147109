


















































import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { MintingViewModel } from '../viewmodels/minting-viewmodel'
@Observer
@Component({
  components: {},
})
export default class CountDown extends Vue {
  @Inject({}) vm!: MintingViewModel
  @Prop({ default: 0 }) value
  @Prop({ default: 0 }) bufferValue
  @Prop({ default: 100 }) totalValue
  @Prop({ default: '#3D3D3D' }) bgFullProgress
  @Prop({ default: '#111111' }) bgBuffer
  @Prop({ default: '' }) endText
  @Prop({ default: '#D6F76B' }) bgSuccess
  @Prop({ default: '24px' }) heightProgress
  @Prop({ default: '' }) bufferText
  @Prop({ default: false }) activeNow
  @Prop({default: false}) hideLabel

  get valuePercentage() {
    return (parseFloat(`${this.value}`) * 100) / this.totalValue
  }
  get validBgFullProgress() {
    if (this.value == 0) return this.bgBuffer
    return this.bgFullProgress
  }

  get isFullProgress() {
    return this.value === this.totalValue
  }
  get isOverBuffer() {
    return this.value > this.bufferValue
  }

  get bufferPercentage() {
    return (parseFloat(`${this.bufferValue}`) * 100) / this.totalValue
  }

  get cssVars() {
    return {
      '--bg-success': this.bgSuccess,
      '--bg-buffer': this.bgBuffer,
      '--bg-full-progress': this.validBgFullProgress,
      '--width-success': this.valuePercentage + '%',
      '--width-buffer': this.bufferPercentage + '%',
      '--height-progress': this.heightProgress,
    }
  }
}
